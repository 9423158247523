<script>
import TermineingForm from "@/views/app/Kanban/Termineinhaltung/TermineingForm.vue";

export default {
  name: "termineingAction",
  components: {TermineingForm},

  data() {
    return {
      isEditing: false,
      loading: false,
    }
  },
  methods: {
    onCreate() {
      this.$refs.termineinModal.openModal()
    },

  },
}
</script>

<template>
  <div class="">
    <div class="">
      <b-button size="sm" variant="info" class="mr-1" :disabled="loading" @click="onCreate">
        <b-spinner v-if="loading" small class="mr-50"/>
        {{ $t('button~new') }}
      </b-button>
    </div>
    <TermineingForm ref="termineinModal"/>
  </div>
</template>

<style scoped>

</style>
